:root {
    // Hotfix for Tailwind 3's transform utilities being buggy in Safari <=14
    // https://github.com/tailwindlabs/tailwindcss/discussions/8788
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;

    // The height of the global header
    --header-height: 74px;
    @screen l {
        --header-height: 90px;
    }

    // The width of the viewport, minus outer margins – or the value of the max-content-width variable, whicheever is smaller
    --content-width: min((100vw - (var(--outer-margin)*2)), var(--max-content-width));

    // The height of the viewport, minus the height of the header and bottom outer margin
    --content-height: calc((var(--vh, 1vh) * 100) - var(--header-height) - var(--outer-margin));

    // The height of the viewport, minus top and bottom outer margins
    --viewport-height: calc((var(--vh, 1vh) * 100) - (var(--outer-margin) * 2));
}

body {
    background-color: theme('colors.white.DEFAULT');
    color: theme('colors.black.DEFAULT');
}

.lazyload,
.lazyloading {
    opacity: 0;
}

.lazyloaded {
    transition: opacity 0.6s theme('transitionTimingFunction.out-quad');
}

.lazyload.lazyzoom, .lazyloading.lazyzoom {
    transform: scale(1.05, 1.05) translate(0, 2vh);
    transform-origin: center bottom;
}

.lazyloaded.lazyzoom {
    transform: scale(1, 1) translate(0, 0);
    transform-origin: center bottom;
    transition: opacity 0.5s theme('transitionTimingFunction.in-sine'), transform 1s theme('transitionTimingFunction.out-quad');
}

.no-js img.lazyload[data-src],
.no-js img.lazyload[data-srcset] {
    display: none;
}

html.outline,
html.no-outline * {
    outline: none !important;
}

svg {
    display: block;
}

// Prevent grid blowout on tiny screens
*:not([data-gridoverlay]) > .grid:not(.blowout) {
    // Max-width = (outerMargins * 2) + (gutter width * number of columns)
    @media (max-width: calc((theme('gutterMap.default') * theme('gridColumns')) + (theme('outerMarginsMap.default') * 2))) {
        grid-template-columns: minmax(0, 1fr);
    }
}

video[playsinline] {
    pointer-events: none;
}

/* clears the 'X' from Internet Explorer */
input[type=search]::-ms-clear,
input[type=search]::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
}

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    display: none;
}

button {
    appearance: none;
    font-weight: normal;
    text-align: left;
}

[hidden] {
    display: none !important;
}

input:required {
    box-shadow: none !important;
}

select {
    appearance: none;
}

input[class*="appearance-none"] {
    -webkit-appearance: none;
}

table {
    th, td {
        text-align: left;
        padding: 0;
        font-weight: normal;
        vertical-align: top;
    }
}

strong, b {
    font-weight: normal;
}

i, em {
    font-style: italic;
}

.hit::before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    min-width: 44px;
    min-height: 44px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

img, video, svg {
    user-drag: none;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

a, button {
    &[disabled],
    &[aria-disabled="true"] {
        cursor: default;
    }
    html.using-touch {
        touch-action: manipulation;
    }
}

details > summary {
    list-style: none;
    cursor: pointer;
}

details > summary > * {
    display: inline;
}

details > summary::-webkit-details-marker {
    display: none;
}

// LINKS
.animated-link {
    text-decoration: none !important;

    background-image: linear-gradient(currentColor, currentColor);
    background-position: 0% 100%;
    background-repeat: no-repeat;
    background-size: 0% 1px;

    &.is-current,
    .using-touch &:active {
        background-size: 100% 1px !important;
    }

    @media (hover: hover) and (pointer: fine) {
        .no-js & {
            transition: background-size var(--duration, 0.3s) theme('transitionTimingFunction.out-quad');
            &:hover,
            .group:hover &,
            &.is-current {
                background-size: 100% 1px;
            }
        }
    }
}

*[class*="underline"],
*[class*="hover:no-underline"],
.wysiwyg a:not([class]) {
    text-underline-offset: 2px;
}

.wysiwyg a:not([class]) {
    text-decoration: underline;
    &:active {
        text-decoration: none;
    }
    @media (hover: hover) and (pointer: fine) {
        text-decoration: none;
    }
}

@supports (text-decoration-color: transparent) {
    *[class*="hover:underline"] {
        text-decoration: underline;
        text-decoration-color: transparent;
        &:active {
            text-decoration-color: currentColor;
        }
        @media (hover: hover) and (pointer: fine) {
            transition: text-decoration-color 0.3s;
            &:hover {
                text-decoration-color: theme('colors.black.DEFAULT');
                // Can't use currentColor because Safari 15 isn't able to transition to it
            }
        }
    }
}

@supports (text-decoration-color: transparent) {
    *[class*="hover:no-underline"],
    .wysiwyg a:not([class]) {
        text-decoration: underline;
        text-decoration-color: currentColor;
        &:active {
            text-decoration: underline !important;
            text-decoration-color: transparent !important;
        }
        @media (hover: hover) and (pointer: fine) {
            transition: text-decoration-color 0.3s;
            &:hover {
                text-decoration: underline !important;
                text-decoration-color: transparent !important;
            }
        }
    }
}

.squish {
    transition: transform 0.1s linear;
    transform: scale(1);
    &:active,
    .group:active & {
        transform: scale(calc(1 - var(--squishyness, 0.03)));
    }
    @media screen and (prefers-reduced-motion: reduce), (update: slow) {
        transform: none !important;
    }
}

.hover-scale {
    @apply group-hover:scale-[1.02];
    transition: transform 0.5s;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    margin: -1px 0 0 -1px;
}

// Reveals
html.js:not(.js-error):not(.is-preview.is-iframe) [data-reveal]:not([data-revealed]) {
    opacity: 0.001;
    @media screen and (prefers-reduced-motion: reduce), (update: slow) {
        transform: none !important;
        opacity: 1 !important;
    }
}
