.page-header {
    &__bar {
        .menu-opening &,
        .menu-open &,
        .menu-closing & {
            position: fixed !important;
            transform: none !important;
        }
    }
}
