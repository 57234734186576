@layer utilities {

    /**

        Værsågod custom Tailwind utilities
        https://tailwindcss.com/docs/adding-custom-styles#adding-custom-utilities

        These utilities are provided via tailwind.config.js:

        .gpu
        .anti
        .round
        .full
        .center
        .center-x
        .center-y
        .clear
        .scrollable
        .scrollable-x
        .scrollbar-hidden

        .wrapper (container with outer margins and max content width)
        .outer-margins (container with outer margins, but no max-width)
        .gutters (gap-based gutters, both horizontal and vertical)
        .gutters-x (gap-based horizontal gutters)
        .gutters-y (gap-based vertical gutters)
        .pad-wrapper (wrapper meant to be used with pad-gutters children, i.e. like the old .g-wrap)
        .pad-gutters (padding-based gutters, both horizontal and vertical)
        .pad-gutters-x (padding-based horizontal gutters)
        .pad-gutters-y (padding-based vertical gutters)

     */

    .ellipsis {
        text-overflow: ellipsis;
        overflow: hidden;
        display: inline-block;
        max-width: 100%;
        white-space: nowrap;
        vertical-align: bottom;
    }

    .overlay::before,
    .vignette::before {
        content: "";
        display: block;
        position: absolute;
        left: 0; top: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
    }

    .overlay::before {
        background-color: theme('colors.black.real');
        opacity: 0.4;
    }

    .vignette::before {
        background: linear-gradient(90deg, rgba(0, 0, 0, 0.00) 48.61%, rgba(0, 0, 0, 0.70) 100%);//, url(<path-to-image>), lightgray 50% / cover no-repeat;
    }

    .hang {
        text-indent: -0.42em;
        @supports (hanging-punctuation: first) {
            hanging-punctuation: first;
            text-indent: 0;
        }
    }

}
